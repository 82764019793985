import { useForm } from "react-hook-form";
import { DateTimeField } from "components/forms/DateTimeField";
import { Form } from "reactstrap";
import { ImportFile } from "Import/types";
import { IconButton } from "components/buttons/IconButton";
import { Save, RefreshCcw } from "react-feather";
import { useMutation, useQueryClient } from "react-query";
import { client } from "utils/auth-client";
import { IMPORTED_FILES_API } from "utils/constants";
import { toast } from "react-toastify";
import { BooleanField } from "components/forms/BooleanField";
import { getDisplayValue } from "utils/helpers";
interface Props {
  file: ImportFile;
}

type Payload = {
  has_double_notes: null | boolean;
};
export const SendHasDoubleNotesButton = ({ file }: Props) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    (data: Payload) =>
      client(`${IMPORTED_FILES_API}${file.id}/edit_has_double_notes/`, {
        method: "POST",
        data: data,
      }),
    {
      onSuccess: () => {
        toast.success("Fatto");
        queryClient.refetchQueries(["history-imports"], { active: true });
      },
      onError: () => {
        toast.error("Errore");
      },
    }
  );

  const { control, handleSubmit } = useForm<Payload>({
    defaultValues: { has_double_notes: file.has_double_notes },
  });
  const onSend = handleSubmit((data: Payload) => {
    mutate(data);
  });

  return (
    <Form onSubmit={onSend}>
      <div className="d-flex flex-row justify-content-center align-items-center">
        {getDisplayValue("has_double_notes", file.has_double_notes, {})}
        <div className="ml-2">
          <IconButton type="submit" Icon={RefreshCcw}></IconButton>
        </div>
      </div>
    </Form>
  );
};
