import classNames from "classnames";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { BooleanField } from "components/forms/BooleanField";
import { FormField } from "components/forms/FormField";
import { AUTOCOMPLETE_URLS } from "utils/constants";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const ImportFileForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Cliente</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.CLIENT}
                      control={control}
                      defaultValue={
                        defaultValues?.client_id
                          ? defaultValues?.client_id
                          : null
                      }
                      errorMessage={errors.client_id?.message}
                      name="client_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Scansionate bolle doppie</b>
                  </td>
                  <td>
                    <BooleanField
                      register={register}
                      name="has_double_notes"
                      errorMessage={errors.has_double_notes?.message}
                    />
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <b>Data arrivo automezzo</b>
                  </td>
                  <td>
                    <DateTimeField
                      disabled={!editable}
                      control={control}
                      name="vehicle_arrival"
                      errorMessage={errors.vehicle_arrival?.message}
                    />
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};
