import { DeleteButton } from "components/buttons/DeleteButton";
import { DownloadFile } from "components/DownloadFile";
import { filterJson } from "components/tables/filters";
import { SendFileInvoiceDateButton } from "Import/components/SendFileInvoiceDateButton";
import { SendHasDoubleNotesButton } from "Import/components/SendHasDoubleNotesButton";
import { SendVehicleArrivalButton } from "Import/components/SendVehicleArrivalButton";
import { ImportFile } from "Import/types";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { Button } from "reactstrap";
import { useGlobalModals } from "shared/context/globalModals";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { IMPORTED_FILES_API } from "utils/constants";
import { getDisplayValue } from "utils/helpers";

const key = "history-imports";
const url = IMPORTED_FILES_API;

export function useOptionsImport() {
  return useOptions(url, key);
}

export function useAddImport() {
  return useAdd<ImportFile>(url, key);
}

export function useDeleteImport() {
  return useDelete(url, key);
}

export function useUpdateImport() {
  return useUpdate<ImportFile>(url, key);
}

export function useListImport(
  params?: any,
  config?: UseQueryOptions<any, unknown, ImportFile[]>
) {
  return useList(url, key, params, config);
}

export function useSearchImport(params?: any) {
  return useSearchPaginated<ImportFile>(url, key, params);
}

export function useImport(
  id: string,
  config?: UseQueryOptions<any, unknown, ImportFile>
) {
  return useGetItem<ImportFile>(id, url, key, config);
}

export function useImportFileColumns() {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteImportedFile } = useDeleteImport();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteImportedFile(id);
    };
    return [
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_importedfile"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              {/* <EditButton
                onClick={() => setActiveItem("import", cell.row.original.id)}
                permissions={[]}
              /> */}
            </div>
          );
        },
      },
      {
        accessor: "id",
        Header: "ID",
        sortType: "basic",
      },
      {
        Header: "Cliente",
        accessor: "client",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.client)
            return (
              <Button
                title={"Mostra soggetto"}
                color="link"
                onClick={() =>
                  setActiveItem("subject", cell.row.original.client.id)
                }
              >{`${cell.row.original.client.id}: ${cell.row.original.client.business_name}`}</Button>
            );
          return "";
        },
        filter: filterJson
      },
      {
        Header: "File",
        accessor: "file",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <DownloadFile
              title={cell.value}
              urlDownload={`${IMPORTED_FILES_API}${cell.row.original.id}/download/`}
            />
          );
        },
      },
      {
        Header: "Arrivo automezzo",
        accessor: "vehicle_arrival",
        sortType: "basic",
        Cell: (cell: any) => (
          <SendVehicleArrivalButton file={cell.row.original} />
        ),
      },
      {
        Header: "Data fatturazione",
        accessor: "invoice_date",
        sortType: "basic",
        Cell: (cell: any) => (
          <SendFileInvoiceDateButton file={cell.row.original} />
        ),
      },
      {
        Header: "Corretto",
        accessor: "correct",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("correct", cell.value, {}),
      },
      {
        Header: "Bolle doppie?",
        accessor: "has_double_notes",
        sortType: "basic",
        Cell: (cell: any) => (
          <SendHasDoubleNotesButton file={cell.row.original} />
        ),
      },
    ];
  }, [deleteImportedFile, setActiveItem]);
}
